<div class="about-section">
  <div class="container">
    <h1>About Us</h1>
    <p class="description animatable">
      SB Consultancy, an leading International Manpower Cosultant, 
      We as SB Consultancy are into Recruitment and manpower supply . We are a team who has our presence in recruitment sphere for the last Five years and now delivering globally to achieve the client’s goal and vision.
    </p>
    <p class="description animatable">
      We take complete responsibility and commitment of delivering the right candidate, at right time and at right place. Our team consists of professionals who with the domain knowledge capitalize on to reach across the global market.
      We believe in the philosophy that our success and growth lies in the satisfaction and growth of our clients. We are expertise in delivering the candidates from senior top management positions and in recent time have developed high competency and network across India in providing skilled and unskilled labours. Our prime focus is on delivering the best resources to our esteemed clients as per their specifications.      
    </p>
  </div>

  <div class="vision-mission">
    <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
      <i class="fa fa-eye icon-large" aria-hidden="true"></i>
      <h3>Vision</h3>
      <p>To be the Leading Human Resources provider Globally.</p>
    </div>
    <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
      <i class="fa fa-certificate icon-large" aria-hidden="true"></i>
      <h3>Mission</h3>
      <p>
        To be an innovative service provider by facilitating Right & Prompt Recruitment
        Solutions of the Positions as desired by our Clients.
      </p>
    </div>
    <div class="box animatable" (mouseenter)="addAnimation($event)" (mouseleave)="removeAnimation($event)">
      <i class="fa fa-users icon-large" aria-hidden="true"></i>
      <h3>Our People</h3>
      <p>
        With their expert knowledge and valuable experience, our Team carries expertise in hiring in various
        domains with excellent closure rates.
      </p>
    </div>
  </div>

  <!-- New Section Added Below -->
  <div class="container">
  <div class="our-team animatable">
    <h3>Our Team</h3>
    <p>
      A team of right people is one of the most important elements and ingredients for the success of any business. 
      Therefore, our endeavour is to hunt for “right people" to enhance business growth of our esteemed clients by 
      connecting them with the right kind of skilled people who can drive their organizations on their way of progress.
    </p>
  </div>

  <div class="word-from-md">
    <h3>A Word From Managing Director</h3>
    <p>
      SB Consultancy continues to grow every day, thanks to the confidence and trust that our clients have in us and in our team, who have the ability to work together towards a common vision. Their efforts act as a fuel that allows our organisation to obtain phenomenal results.
    </p>
    <p>
      We are rendering our services to several industries and corporates at different levels right from senior positions to labour positions .In recent years, we have developed a good network and data base in different categories like Oil and Gas, Construction, Hospitality ,security, manufacturing ,information technology, Retail, Irrigation, Facilities Management and many more. We have established our presence in different parts of India with Interview facility and trade test centre.
    </p>
    <p>
      A step ahead, we have developed our own portal and mobile application to reach the right and deserving candidates in every corner of India. Also we have a complete automized system of selecting the right candidate to up to his departure.
    </p>
    <p>
      SB Consultancy organises a complete training and behaviour consultation seminar before the departure of the candidates to make them aware of the country rules & regulations, basic language understanding, site safety, basic psychological training and Information about the organisation.
    </p>
    <p>
      Considering the global requirement, we are expanding ourselves and therefore able to select candidates from Nepal, Sri Lanka.
    </p>
    <p>
      Openness creates opportunities for dialog with our Customers, Business partners, Stakeholders and Employees. We maintain corporate transparency and conduct ethical business practices, which would enable us about the awareness of emerging problems and fast resolution turn around and enhance our efficiency.
    </p>
    <p>
      Lastly, I would like to thank all our valuable customers, business partners, and employees for their continuous 
      support and strength given to us in our continuous success, and achieving our vision and goals.
    </p>
  </div>
 </div> 
</div>
