import * as moment from 'moment';
import { UploadCvService } from './upload-cv.service';
import { Component, OnInit } from '@angular/core';
import { send } from 'process';
import { rolepojo } from 'src/app/pojo/rolepojo';
import { uploadcvpojo } from 'src/app/pojo/upload-cvpojo';
import { uploadPojo } from 'src/app/pojo/uploadpojo';
import { contactuspojo } from 'src/app/pojo/contactusPojo';


declare var bootbox: any;
declare var $: any;

@Component({
  selector: 'app-upload-cv',
  templateUrl: './upload-cv.component.html',
  styleUrls: ['./upload-cv.component.scss']
})
export class UploadCvComponent implements OnInit{
  uploadcv = new uploadcvpojo();
  jobSeekerForm = new contactuspojo();
  selectedFiles: File[] = [];
  imagee :any;
  imagePojo:uploadPojo = new uploadPojo();
  id: any;
  file:any;
  filePath:string|any;
  btnstate: boolean=true;
  role : rolepojo[];

  hh:boolean;
  createBtnFlag: boolean;
  constructor(private UploadCvService: UploadCvService) { }

  ngOnInit()  {
    // this.getGalleryFolderAll();
    // ANIMATION
//  this.getrole();
 this.uploadcv.date = moment().format('YYYY-MM-DD').slice(0, 10);
 console.log(this.uploadcv.date );
    $(document).ready(function($) {
  
      // Function which adds the 'animated' class to any '.animatable' in view
      var doAnimations = function() {
        
        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
            $animatables = $('.animatable');
        
        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }
        
        // Check all animatables and animate them if necessary
        $animatables.each(function(i) {
           var $animatable = $(this);
          if (($animatable.offset().top + $animatable.height() -1) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
          }
        });
    
      };
      
      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');
    
    });
 }



 submit() {
  this.createBtnFlag = true;

  // Validation: Check if all required fields are filled
  const requiredFields = [
    'firstName', 'lastName', 'gender', 'mailId', 'dob',
    'mobileNo', 'qualification', 'designation', 'experience', 'passPortNo',
    'cSalary', 'address', 'city', 'postalCode', 'country'
  ];

  const isValid = requiredFields.every((field) => this.jobSeekerForm[field]);

  if (!isValid) {
    bootbox.alert({
      message: 'Please fill all required fields before submitting.',
      className: 'text-danger h5 text-center',
      closeButton: false,
    });
    this.createBtnFlag = false;
    return; // Stop further execution if validation fails
  }

  const payload = { ...this.jobSeekerForm }; // Clone form values

  // Log the payload to check its content
  console.log('Payload:', payload);

  this.UploadCvService.PostJobSeekerForm(payload).subscribe({
    next: () => {
      bootbox.alert({
        message: 'Form submitted successfully',
        className: 'text-success h5 text-center',
        closeButton: false,
      });

      // Reset form values after successful submission
      this.jobSeekerForm = new contactuspojo();
      (<HTMLFormElement>document.getElementById('addForm')).reset();
      $('#createModal').modal('hide');
      this.createBtnFlag = false;
    },
    error: (error) => {
      bootbox.alert({
        message: `Submission failed. HTTP Status: ${error.status}`,
        className: 'text-danger h5 text-center',
        closeButton: false,
      });
      this.createBtnFlag = false;
    },
  });
}




setFiles(images) {
  this.selectedFiles = [];
  for (let i = 0; i < images.files.length; i++) {
      this.selectedFiles.push(images.files[i]);
  }
}

//  send() {
//   this.uploadcv.files = this.imagePojo.filePath;
//   this.uploadcv.date = moment().format('YYYY-MM-DD').slice(0, 10);
//   console.log(this.uploadcv);
//   this.UploadCvService.saveuploadcvdetails(this.uploadcv).subscribe(res => {
//     console.log(this.uploadcv);
//     this.uploadcv = res;
//     this.reset();
//     alert('Your Application Has Been Successfully Submited')
// });
// }

reset(){
  this.uploadcv.id='';
  this.uploadcv.fullName='';
  // this.uploadcv.experience='';
  // this.uploadcv.files='';
  this.uploadcv.mailId='';
  this.uploadcv.phoneNo=null;
  // this.uploadcv.skills='';
  // this.uploadcv.role='';
  this.imagePojo.filePath= null;
  this.file=null;
  this.btnstate = true;
}

setFile(files:any){
  let file = files.item(0);
  console.log(file)
}

removeFile(i) {
  this.selectedFiles.splice(i, 1);
}

// getrole(){
//   this.UploadCvService.getRole().subscribe(res =>{
//     this.role = res;
//   })
// }
}
  

  


  




