<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Home Page</title>
  <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet">
  <link href="https://cdn.jsdelivr.net/npm/animate.css" rel="stylesheet">
  <link rel="stylesheet" href="home-page.component.scss">
</head>
<body>
  <div class="slideshow-container">
    <div class="slide" *ngFor="let slide of slides; let i = index" [class.active]="i === currentIndex">
      <img [src]="slide.image" alt="Slide Image" class="slide-image" />
      <div class="caption">{{ slide.caption }}</div>
    </div>
  </div>
  

</body>
</html>
