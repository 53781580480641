import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticketing',
  templateUrl: './ticketing.component.html',
  styleUrls: ['./ticketing.component.scss']
})
export class TicketingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
