import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { contactuspojo } from 'src/app/pojo/contactusPojo';
import { rolepojo } from 'src/app/pojo/rolepojo';
import { uploadcvpojo } from 'src/app/pojo/upload-cvpojo';
import { uploadPojo } from 'src/app/pojo/uploadpojo';
import { staticport } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class UploadCvService {

  constructor( private HttpClient: HttpClient) { }
  

  // saveuploadcvdetails(arg:uploadcvpojo):Observable<uploadcvpojo>{
  //   const url = staticport.porturl + 'AddCareers';
  //   return this.HttpClient.post<uploadcvpojo>(url, arg);
  //  }
   PostJobSeekerForm(arg: contactuspojo): Observable<contactuspojo> {
    const url = staticport.porturl + 'AddCareers';
    return this.HttpClient.post<contactuspojo>(url, arg);
  }

  
getRole():Observable<rolepojo[]>{
  const url=staticport.porturl+'getAllRole';
  return this.HttpClient.get<rolepojo[]>(url);
}
}
