<div class="title-box uk-border-rounded p-4" style="background-color: #ffffff; border: 1px solid #e1e1e1; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
    <div class="header-section bg-dark text-white p-3 rounded-top text-center">
      <h1 class="title-text font-weight-bold">Immigration and Visa Assistance</h1>
    </div>
    <div class="breadcrumb-section mt-3 text-right">
      <h5 class="text-muted">
          <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
          <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
          <span class="sub-menu" routerLink="/Ticketing">Ticketing</span>
        </h5>
      </div>
    </div>
  <!--------------------------------------------------TITLE END-------------------------------------------->
  <!-- Image Section -->
  <div class="text-center my-4">
    <img src="../../../assets/img/home/Immigrationandvisa.jpg" alt="Company Image" class="responsive-img">
  </div>
  
  <!-- Description Section -->
  <div class="description-text text-justify mx-3">
    <p>
        <span>SB Consultants Visa process consultants with in-depth knowledge of immigration process will counsel & take over your case.</span><br>
        <span>They guide you step by step in processing your visa application and filing of your immigration petition for Canada, immigration petition for New Zealand, etc.</span><br>
        <span>That apart, Thane branch is also known for processing applications for Germany Job Seeker Visa, Denmark Green Card, and many other visas.</span><br>
        <span>We are already being recognized as the premier visa agents in Thane.</span>
      </p>
    <p>
        <span>The pulse of migration is good counseling. People here at this office have mastered the art of guiding people in accordance with their career needs.</span><br>
        <span>Therefore, education counseling is offered to many young grads looking out for study abroad options.</span><br>
        <span>Our presence in Mumbai has made us understand that this city is not just the financial capital of India, but is also warm and open to accepting fellow Indians and helps them to grow.</span><br>
        <span> We have assisted 1000s of young aspirants so far, and continue to do so. We heartily thank Mumbaikars for the trust and confidence shown in us by them.</span>
    </p>
    <p>
        You can give us a call or pay a quick visit to discuss your immigration application with us.
    </p>
  </div>
  
  <style>
    /* Main Container */
  .content-container {
    display: flex;
    justify-content: space-between; /* Space between left and right columns */
    gap: 20px; /* Adds spacing between columns */
    flex-wrap: wrap; /* Allows columns to wrap on smaller screens */
    padding: 20px;
  }
  
  /* Left Column */
  .left-column {
    width: 48%; /* Takes half the page for larger screens */
    text-align: left;
  }
  
  /* Right Column */
  .right-column {
    width: 48%; /* Takes the other half of the page for larger screens */
    text-align: left;
  }
  
  /* Style for Headings */
  .right-aligned {
    color: #007bff;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Style for Lists */
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    padding-left: 1.5rem;
    position: relative;
  }
  
  /* Add bullets using ::before */
  ul li::before {
    content: '●';
    position: absolute;
    left: 0;
    color: #007bff;
    font-size: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Add spacing between the first and second sections in the right column */
  .right-column p + ul {
    margin-top: 10px;
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 768px) {
    .left-column,
    .right-column {
      width: 100%; /* Stacks both columns */
      margin-bottom: 20px; /* Adds spacing between stacked sections */
    }
    
    .content-container {
      gap: 0; /* Removes gap for stacked layout */
    }
  }
  .responsive-img {
  width: 80%;              
  height: auto;               
  object-fit: cover;        
  max-height: 800px;          
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .responsive-img {
    width: 80%;              
    height: auto;             
    object-fit: cover;        
    max-height: 800px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .responsive-img {
    width: 80%;              
    height: auto;             
    object-fit: cover;       
    max-height: 800px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .responsive-img {
    width: 80%;              
    height: auto;            
    object-fit: cover;       
    max-height: 700px;        
  }
}
    /* Animation Styles */
  .animatable {
    opacity: 0;
    transform: translateX(100px); /* Starts off to the right */
    transition: opacity 1s ease-out, transform 4s ease-out;
  }
  
  /* For elements that animate from the right */
  .animate-from-right {
    animation: slideInFromRight 4s forwards;
  }
  
  /* For elements that animate from the left */
  .animate-from-left {
    animation: slideInFromLeft 4s forwards;
  }
  
  /* Keyframes for sliding in from the right */
  @keyframes slideInFromRight {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Keyframes for sliding in from the left */
  @keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Optional: Add a delay for the first element */
  .animatable.animate-from-right {
    animation-delay: 0.2s;
  }
  
  .animatable.animate-from-left {
    animation-delay: 0.4s;
  }
  
  /* Other styling for text */
  .description-text p {
    font-size: 18px; /* Adjust as per preference */
    color: #333;
    margin: 10px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .description-text p {
      font-size: 16px; /* Adjust for smaller screens */
    }
  }
    /* Global Styles */
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center; /* Centers horizontally */
      justify-content: center; /* Centers vertically */
      min-height: 100vh; /* Ensures full-page height */
      background-color: #f9f9f9; /* Optional background color */
    }
  
    p {
      text-align: center;
      font-size: 18px; /* Adjust as per preference */
      color: #333; /* Optional text color */
      margin: 10px 0;
    }
  
    .highlight-text {
      font-weight: bold;
      color: #007bff; 
    }
  
    /* Default Image Styling for all screen sizes */
  .responsive-img {
    width: 100%;              
    height: auto;               
    object-fit: cover;        
    max-height: 500px;          
  }
  
  /* Mobile and tablet responsiveness */
  @media (max-width: 768px) {
    .responsive-img {
      width: 100%;              
      height: auto;             
      object-fit: cover;        
      max-height: 300px;       
    }
  }
  
  /* For larger screen sizes like tablets and desktops */
  @media (min-width: 769px) and (max-width: 1024px) {
    .responsive-img {
      width: 100%;              
      height: auto;             
      object-fit: cover;       
      max-height: 400px;       
    }
  }
  
  /* For desktop view */
  @media (min-width: 1025px) {
    .responsive-img {
      width: 80%;              
      height: auto;            
      object-fit: cover;       
      max-height: 500px;        
    }
  }
  
  
   /* Container to hold bullet points and headings */
  .bullet-container {
    display: flex;
    flex-direction: column;  /* Stack items vertically */
    align-items: flex-end;   /* Align items to the right */
    margin-bottom: 30px;     /* Space between sections */
  }
  
  .bullet-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;   /* Align list items to the right */
  }
  
  .right-aligned {
    text-align: right;       /* Align text to the right */
    margin-bottom: 10px;     /* Space between heading and list */
  }
  
  /* Custom Style for List Items */
  .bullet-column ul {
    list-style-type: none;
    padding-left: 0;
    text-align: right;       /* Align the list items to the right */
  }
  
  .bullet-column ul li {
    padding-left: 1.5rem;    /* Indentation for bullet points */
    position: relative;
  }
  
  .bullet-column ul li::before {
    content: '●';
    position: absolute;
    left: 0;
    color: #007bff;
    font-size: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Optional: For animation classes */
  .animatable {
    transition: all 0.5s ease-in-out;
  }
  
  .animate-from-left {
    animation: slideInLeft 1s forwards;
  }
  
  .animate-from-right {
    animation: slideInRight 1s forwards;
  }
  
  /* Keyframes for animation */
  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  </style>
   
