<!-- Footer -->
 <div class="container1">
 <div class="text-container1"><p >looking for job opportunity</p></div> 
  <div class="button1"><button class="button-in" routerLink='/upload-cv' >Apply</button></div>
 </div>
<footer class="bg-dark text-white py-5">
    <div class="container">
      <div class="row">
        <!-- Logo Section -->
        <div class="col-md-4">
          <a class="navbar-brand" href="#">
            <img class="logo-img" src="../../assets/img/home/SBlogo-removebg-preview.png" alt="SB Consultancy" />
          </a> 
        <p>Our SB Consultancy recruitment solutions are about offering the opportunity to clients & Candidates in such a way that it works for them in a mutually beneficial way.</p>
        </div>
  
        <!-- Important Links Section -->
        <div class="col-md-4">
          <h5>Important Links</h5>
          <ul class="list-unstyled">
            <li><a href="#">Home</a></li>
            <li><a routerLink="/about-us">About Us</a></li>
            <li><a routerLink="/web-development" class="text-white">Services</a></li>
            <li><a routerLink="/verticals">Industries We Serve</a></li>
            <li><a routerLink="/ourproducts">Recruitment Process</a></li>
            <li><a routerLink="/upload-cv">Job Seeker</a></li>
            <li><a routerLink="/contact-us">Contact</a></li>
          </ul>
        </div>
  
        <!-- Contact Info Section -->
        <div class="col-md-4">
          <h5>Contact Info</h5>
          <p>Ahila Complex, Shop No:B11 & B12, Odathurai Street Thiruvaiyaru, Thanjavur - Dt,Pin - 613204, Tamil Nadu.</p>
          <p>+91 98943 55288</p>
          <p><a href="mailto:sbconsultancytnj@gmail.com" class="text-white">sbconsultancytnj&#64;gmail.com</a></p>
        </div>
      </div>
  
      <!-- Copyright Section -->
      <div class="text-center mt-4">
        <a href="http://annaasilicontechnology.com/#/" target="_blank">
          <p style="font-size: 20px; font-weight: bold; color: #f8f6f6;" class="responsive-text">
            Designed by AnnaaSiliconTechnology.Pvt.Ltd.,®
          </p>
        </a>
        <p>&copy; 2025 SB Consultants. All rights reserved 2025</p>
      </div>
    </div>
  </footer>
  <style>
    .responsive-text {
      font-size: 5px;
      font-weight: bold;
      color: #f8f6f6;
    }
  
    /* For mobile devices */
    @media (max-width: 576px) {
      .responsive-text {
        font-size: 2px; /* Adjust as needed */
      }
    }
  
    /* For tablets */
    @media (min-width: 577px) and (max-width: 768px) {
      .responsive-text {
        font-size: 5px; /* Adjust as needed */
      }
    }
  
    /* For desktops */
    @media (min-width: 769px) {
      .responsive-text {
        font-size: 30px; /* Default size */
      }
    }
  </style>
  