<div class="title-box uk-border-rounded p-4" style="background-color: #ffffff; border: 1px solid #e1e1e1; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
	<div class="header-section bg-dark text-white p-3 rounded-top text-center">
	  <h1 class="title-text font-weight-bold">Our Industries</h1>
	</div>
  </div>
	  <!--------------------------------------------------TITLE END-------------------------------------------->
	<!-- Image Section -->
	 <div class="container-img">
		<div class="text-center my-4">
			<img style="width: 300px; height: 300px; " src="../../assets/img/home/it.jpg" alt="Company Image" class="responsive-img">
		</div>
		<div class="text-center my-4">
			<img style="width: 300px; height: 300px; " src="../../assets/img/home/construction.jpg" alt="Company Image" class="responsive-img">
		</div>
		<div class="text-center my-4">
			<img style="width: 300px; height: 300px; " src="../../assets/img/home/manufacture.jpg" alt="Company Image" class="responsive-img">
		</div>
		<div class="text-center my-4">
			<img style="width: 300px; height: 300px; " src="../../assets/img/home/oil.jpg" alt="Company Image" class="responsive-img">
		</div>

	 </div>

  
  
  
  
  <!-- Description Section -->
  <div class="description-text text-justify mx-3">
	<!-- Responsive Two-column List -->
	<div class="bullet-container">
	  <div class="bullet-column">
		<ul class="animatable animate-from-right">
		  <li>Advertisement</li>
		  <li>Agriculture and Horticulture</li>
		  <li>Air conditioning and Refrigeration</li>
		  <li>Aluminum</li>
		</ul>
	  </div>
	  <div class="bullet-column">
		<ul class="animatable animate-from-left">
		  <li>Architects, Auditing and Accounting</li>
		  <li>Automobile Engineering and Workshops</li>
		  <li>Aviation, Airlines and Airports Maintenance</li>
		</ul>
	  </div>
	</div> 
	<hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	<div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Banking and Finance</li>
			<li>Bakery Products</li>
			<li>Bottling Plants(Soft Drinks)</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Building Contracting, Maintenance and Materials</li>
			<li>Buildings-Portable and Pre-Fabricated</li>
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Cable/ Wire Manufacturing and Contracting</li>
			<li>Can Manufacturing</li>
			<li>Catering Services</li>
			<li>Cement Plants</li>
			<li>Chemical and Petrochemical Complexes</li>
			<li>Chartered Engineers</li>
			<li>Cleaning and Maintenance Services</li>
			<li>Civil Engineering, Contracting and Maintenance</li>
			<li>Construction, Operations and Maintenance</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Commercial and Retail</li>
			<li>Clearing and Forwarding Service – Air and Sea</li>
			<li>Cold Storage Handling and Maintenance</li>
			<li>Corrosion Control Services</li>
			<li>Concrete Blocks/ Precast</li>
			<li>Concrete/ Ready Mix</li>
			<li>BankConcrete Services</li>
			<li>Construction</li>
			<li>Computers Hardware, Software and Maintenance</li>
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Dairy</li>
			<li>Desalination Engineering Services</li>
			<li>De-Watering Services</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Diesel Engineering, Repairs and Maintenance</li>
			<li>Drilling/Contracting Services</li>
			<li>Distributors, Wholesale Traders - Consumer Goods</li>
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Engines and Turbines</li>
			<li>Engineering & Project Management</li>
			<li>Exhibition and Trade Fair Organizers</li>
			<li>Educational Services</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Electric Power Generation</li>
			<li>Electrical Contracting and Maintenance</li>
			<li>Electronics Engineering</li>
			<li>Engineering Procurement Installation Construction (EPC)</li>	
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Fashion</li>
			<li>Fabrication (Structure and Pressure Vessels)</li>
			<li>Fast-Moving Consumer Goods (FMCG)</li>
			<li>Food and Beverages</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Fertilizer</li>
			<li>Fire-Fighting Services</li>
			<li>Food Processing</li>
			<li>Furniture and Interior Décor</li>	
		  </ul>
		</div>
	  </div>
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;"> 
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Gold</li>
			<li>Government Sectors</li>
			<li>Gas Distribution Services</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>General Trading and Marketing Services</li>
			<li>Glass Manufacturing Services</li>	
		  </ul>
		</div>
	  </div>
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;"> 
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Health & Beauty</li>
			<li>Healthcare</li>
			<li>Hospitality</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Hospitals and Clinics</li>
			<li>Hotel Industry</li>	
			<li>Human Resource Solutions</li>	
		  </ul>
		</div>
	  </div>
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;"> 
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Industrial Safety</li>
			<li>Interior Designing</li>
			<li>Instrumentation</li>
			<li>IT and Telecommunications</li>
			<li>Installation and Maintenance Services</li>
			<li>Insulation Contracting</li>
			<li>Inspection Services</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Insurance Services</li>
			<li>Industrial Chemicals</li>
			<li>Infrastructure and Industrial Construction</li>
			<li>Investment Banking/ Merchant Banking and Corporate Finance</li>
			<li>Iron and Steel</li>
			<li>Irrigation Contracting Services</li>
			<li>Laundry and Dry Cleaning Services</li>
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Market Research and Media Research Firms</li>
			<li>Media – Print, Publishing and E-Publishing</li>
			<li>Metalworking Machinery and Equipment</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Manufacturing Industry</li>
			<li>Mine and Refinery</li>	
			<li>Mills</li>	
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Oil and Gas, Petroleum Refinery</li>
			<li>Operation and Maintenance (High-Rise Buildings)</li>
			<li>Operation and Maintenance (Industrial)</li>
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Packaging Material Manufacturers</li>
			<li>Paints & Emulsions</li>
			<li>Pharmaceuticals, Biopharmaceuticals and Bulk Drugs</li>
			<li>Printing Firms</li>
			<li>Petrochemical and Process</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Pesticides and Agro Chemicals</li>
			<li>Power and Utility</li>	
			<li>Poly-silicon</li>	
			<li>Ports</li>	
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Real Estate</li>
			<li>Refinery</li>
			<li>Railways and Metro Rail</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Road Transport and Surface Transport Services</li>
			<li>Rubbers, Plastics and Polymers</li>	
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Solar</li>
			<li>Sports</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Steel</li>
			<li>Service and Consulting</li>	
		  </ul>
		</div>
	  </div> 
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Textiles</li>
			<li>Trading</li>
		  </ul>
		</div>
		<div class="bullet-column">
		  <ul class="animatable animate-from-left">
			<li>Teaching and Educational Administration</li>
			<li>Travel and Tourism</li>	
		  </ul>
		</div>
	  </div>
	  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;"> 
	  <div class="bullet-container">
		<div class="bullet-column">
		  <ul class="animatable animate-from-right">
			<li>Warehousing</li>
			<li>Water Treatment and Desalination</li>
		  </ul>
		</div>
	</div>
  </div>
  
  <style>
	/* Animation Styles */
  .animatable {
	opacity: 0;
	transform: translateX(100px); /* Starts off to the right */
	transition: opacity 1s ease-out, transform 4s ease-out;
  }
  
  /* For elements that animate from the right */
  .animate-from-right {
	animation: slideInFromRight 4s forwards;
  }
  
  /* For elements that animate from the left */
  .animate-from-left {
	animation: slideInFromLeft 4s forwards;
  }
  
  /* Keyframes for sliding in from the right */
  @keyframes slideInFromRight {
	0% {
	  opacity: 0;
	  transform: translateX(100px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
  
  /* Keyframes for sliding in from the left */
  @keyframes slideInFromLeft {
	0% {
	  opacity: 0;
	  transform: translateX(-100px);
	}
	100% {
	  opacity: 1;
	  transform: translateX(0);
	}
  }
  
  /* Optional: Add a delay for the first element */
  .animatable.animate-from-right {
	animation-delay: 0.2s;
  }
  
  .animatable.animate-from-left {
	animation-delay: 0.4s;
  }
  
  /* Other styling for text */
  .description-text p {
	font-size: 18px; /* Adjust as per preference */
	color: #fefcfc;
	margin: 10px 0;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
	.description-text p {
	  font-size: 16px; /* Adjust for smaller screens */
	}
  }
	/* Global Styles */
	body {
	  font-family: Arial, sans-serif;
	  margin: 0;
	  padding: 0;
	  display: flex;
	  flex-direction: column;
	  align-items: center; /* Centers horizontally */
	  justify-content: center; /* Centers vertically */
	  min-height: 100vh; /* Ensures full-page height */
	  background-color: #f9f9f9; /* Optional background color */
	}
  
	p {
	  text-align: center;
	  font-size: 18px; /* Adjust as per preference */
	  color: #333; /* Optional text color */
	  margin: 10px 0;
	}
  
	.highlight-text {
	  font-weight: bold;
	  color: #007bff; 
	}
  
	/* Default Image Styling for all screen sizes */
  .responsive-img {
	width: 100%;              
	height: auto;               
	object-fit: cover;        
	max-height: 500px;          
  }
  .container-img{
	display:flex;
	width: 100%;
	margin: auto;
	align-items: center;
	justify-content: center;
	gap: 10px;

  }
  
  /* Mobile and tablet responsiveness */
  @media (max-width: 768px) {
	.responsive-img {
	  width: 100%;              
	  height: auto;             
	  object-fit: cover;        
	  max-height: 300px;       
	}
	.container-img{
		flex-direction: column;
	}
  }
  
  /* For larger screen sizes like tablets and desktops */
  @media (min-width: 769px) and (max-width: 1024px) {
	.responsive-img {
	  width: 80%;              
	  height: auto;             
	  object-fit: cover;       
	  max-height: 800px;       
	}
  }
  
  /* For desktop view */
  @media (min-width: 1025px) {
	.responsive-img {
	  width: 80%;              
	  height: auto;            
	  object-fit: cover;       
	  max-height: 700px;        
	}
  }
  
  
	/* Bullet Container */
	.bullet-container {
	  display: flex;
	  justify-content: space-between;
	  gap: 20px; 
	  max-width: 900px;
	  margin: 20px auto; 
	}
  
	/* Bullet Column Styling */
	.bullet-column {
	  flex: 1;
	  list-style: none; 
	  padding: 0;
	}
  
	.bullet-column ul {
	  padding-left: 50px; 
	  margin: 0;
	}
  
	.bullet-column li {
	  position: relative;
	  margin-bottom: 10px; 
	  font-size: 16px;
	  font-family: Arial, sans-serif;
	  color: #000;
	  line-height: 1.6;
	}
  
	/* Custom Bullet Design */
	.bullet-column li::before {
	  /* content: '●';  */
	  position: absolute;
	  left: -20px;
	  /* color: #007bff; */
	  font-size: 20px;
	}
  
	/* Media Queries for Mobile */
	@media (max-width: 768px) {
	  .bullet-container {
		flex-direction: column; 
		gap: 10px; 
		margin: 10px auto; 
	  }
  
	  .bullet-column {
		padding-left: 10px;
	  }
  
	  .bullet-column ul {
		padding-left: 30px; 
	  }
  
	  .bullet-column li {
		font-size: 14px; 
		margin-bottom: 8px; 
	  }
  
	  .bullet-column li::before {
		left: -15px; 
		font-size: 18px; 
	  }
	}
  
  </style>
  