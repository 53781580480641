import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-home-navbar',
  templateUrl: './home-navbar.component.html',
  styleUrls: ['./home-navbar.component.scss']
})
export class HomeNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Highlight the active navbar link
    $('.navbar-nav .nav-link').on('click', function () {
      $('.navbar-nav .nav-link').removeClass('active');
      $(this).addClass('active');
    });

    // Collapse navbar after clicking a link (for mobile view)
    $('.navbar-nav > li > a, .navbar-nav > li > div > a').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });

    // Add animation for brand name appearance on scroll
    $(window).on('scroll', function () {
      const scroll = $(window).scrollTop();
      if (scroll > 50) {
        $('.brandname').fadeIn('slow').addClass('show');
      } else {
        $('.brandname').fadeOut('slow').removeClass('show');
      }
    });
  }
}
