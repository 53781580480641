<div class="title-box uk-border-rounded p-4" style="background-color: #ffffff; border: 1px solid #e1e1e1; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
  <div class="header-section bg-dark text-white p-3 rounded-top text-center">
    <h1 class="title-text font-weight-bold">Recruitment Process</h1>
  </div>
  <div class="breadcrumb-section mt-3 text-right">
    <h5 class="text-muted">
        <span class="sub-menu" routerLink="/home-page">Home </span>
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <!-- <span class="sub-menu" routerLink="/Immigration">Immigration and Visa Assistance</span> -->
      </h5>
    </div>
  </div>
<!--------------------------------------------------TITLE END-------------------------------------------->
<!-- Image Section -->
<!-- <div class="text-center my-4">
  <img src="../../../assets/img/home/Training People.jpg" alt="Company Image" class="responsive-img">
</div> -->


<!-- Description Section -->
<div class="description-text text-justify mx-3">
  <p  class="animatable animate-from-right">
    <span> Our capabilities are completely scalable and our processes provide industry-leading results,regardless of the size of your company or the project in question.</span><br>
    <span>Some of theareas where we assist our clients are:</span>
  </p>

  <p style="font-size: 35px; font-weight: bold; color: #007BFF;">
    Selection
  </p>
  <div class="text-center my-4">
  <img src="../../assets/img/contact/Selection.jpg" alt="Company Image" class="responsive-img">
</div>
    <p>
      We prefer selection process either by practical test or oral test, which conduct under the direction of the authorized representative. We arrange his/her accommodation, transport and all other support required for the purpose. In case we are entrusted with the selection & testing of the worker, we normally do it with the help of a team of knowledgeable experts for technical & professional categories under the direct supervision of our professional executives.
    </p>
  <p style="font-size: 35px; font-weight: bold; color: #007BFF;">
    Medical Checkup
  </p>

  <div class="text-center my-4">
    <img src="../../assets/img/contact/Medicalcheckup.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left">
    After the selection of the candidates, we send them for the medical checkup in the authorized medical center of the respective Embassies of the nation of employment.
  </p>
  <p style="font-size: 35px; font-weight: bold; color: #007BFF;">
    Application for VISA
  </p>
  <div class="text-center my-4">
    <img src="../../assets/img/contact/VisaForm.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left">
    After the final selection of the selected candidate, we apply for the visa as per the authorized term of the embassy of countries.
  </p>
  <p style="font-size: 35px; font-weight: bold; color: #007BFF;">
    Ticketing and Emigration
  </p>
  <div class="text-center my-4">
    <img src="../../assets/img/contact/Ticketing and Emigration.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left">
    To make possible travelling as per plan, the personnel may send PTA or revoke obligatory transportation expenditure in compliance of SB Consultants. Our firm attains departure authorization from the respective Govt. Dept. for the applicants.
  </p>
  <p style="font-size: 35px; font-weight: bold; color: #007BFF;">
    Orientation before Departure
  </p>
  <div class="text-center my-4">
    <img src="../../assets/img/contact/Orientation before Departure.jpg" alt="Company Image" class="responsive-img">
  </div>
  <p class="animatable animate-from-left">
    Our organization offers vitaldirection to workers before travelling overseas. The personnel is well-versedabout their duties and responsibilities while the overseas and firsthandacquaintance of work environment there and most important features of labor laws of the country of employment.
  </p>
  
  

  

<style>
  /* Animation Styles */
.animatable {
  opacity: 0;
  transform: translateX(100px); /* Starts off to the right */
  transition: opacity 1s ease-out, transform 4s ease-out;
}

/* For elements that animate from the right */
.animate-from-right {
  animation: slideInFromRight 4s forwards;
}

/* For elements that animate from the left */
.animate-from-left {
  animation: slideInFromLeft 4s forwards;
}

/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for sliding in from the left */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Optional: Add a delay for the first element */
.animatable.animate-from-right {
  animation-delay: 0.2s;
}

.animatable.animate-from-left {
  animation-delay: 0.4s;
}

/* Other styling for text */
.description-text p {
  font-size: 18px; /* Adjust as per preference */
  color: #333;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .description-text p {
    font-size: 16px; /* Adjust for smaller screens */
  }
}
  /* Global Styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
    min-height: 100vh; /* Ensures full-page height */
    background-color: #f9f9f9; /* Optional background color */
  }

  p {
    text-align: center;
    font-size: 18px; /* Adjust as per preference */
    color: #333; /* Optional text color */
    margin: 10px 0;
  }

  .highlight-text {
    font-weight: bold;
    color: #007bff; 
  }

  /* Default Image Styling for all screen sizes */
.responsive-img {
  width: 100%;              
  height: auto;               
  object-fit: cover;        
  max-height: 800px;          
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .responsive-img {
    width: 100%;              
    height: auto;             
    object-fit: cover;        
    max-height: 300px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .responsive-img {
    width: 80%;              
    height: auto;             
    object-fit: cover;       
    max-height: 800px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .responsive-img {
    width: 80%;              
    height: auto;            
    object-fit: cover;       
    max-height: 700px;        
  }
}


  /* Bullet Container */
  .bullet-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; 
    max-width: 900px;
    margin: 20px auto; 
  }

  /* Bullet Column Styling */
  .bullet-column {
    flex: 1;
    list-style: none; 
    padding: 0;
  }

  .bullet-column ul {
    padding-left: 50px; 
    margin: 0;
  }

  .bullet-column li {
    position: relative;
    margin-bottom: 10px; 
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #000;
    line-height: 1.6;
  }

  /* Custom Bullet Design */
  .bullet-column li::before {
    /* content: '●';  */
    position: absolute;
    left: -20px;
    /* color: #007bff; */
    font-size: 20px;
  }

  /* Media Queries for Mobile */
  @media (max-width: 768px) {
    .bullet-container {
      flex-direction: column; 
      gap: 10px; 
      margin: 10px auto; 
    }

    .bullet-column {
      padding-left: 10px;
    }

    .bullet-column ul {
      padding-left: 30px; 
    }

    .bullet-column li {
      font-size: 14px; 
      margin-bottom: 8px; 
    }

    .bullet-column li::before {
      left: -15px; 
      font-size: 18px; 
    }
  }

</style>