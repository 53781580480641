export class uploadPojo {
    id:string |any;
    name:string|any;
    type:string|any;
    filePath:string|any;
    // slideid:string|any;
    fullName: string;
    mailId: string;
    phoneNo: string;
    message: string;

}