<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Contact Us</title>
</head>
<body>
  <section class="contact-us">
    <!-- <h1>Contact Us</h1> -->
    <div class="content">
      <p style="font-size: 35px; font-weight: bold; color: #007BFF; text-align: center;">
        Our Presence
      </p>      
      <p>
        SB Consultants head office is located in India. We
        provide all types of best skilled candidates through a widespread network of offices and recruiting associates
        operating in metros and small towns of India. In addition, we have made international tie-ups to tap talent in
        Nepal, Bangladesh, and other South-Asian countries.
      </p>
      <p>
        Our network keeps track of a nationwide database of professionals of all grades, starting from top to low-level
        management professionals and general workforce for various industries.
      </p>
      <p>
        With the help of our quality staff that helps in recruitment drive campaigns, we achieve success in our work.
        Also, we can easily arrange interviews at our different locations depending on the requirement.
      </p>
    </div>
  </section>

  <section class="contact-form">
    <h2>Contact Form</h2>
    <div class="form-container">
      <form action="#" method="POST"  autocomplete="off">
        <input
                    type="text"
                    id="fullName"
                    class="form-control"
                    [(ngModel)]="contactus.fullName"
                    name="fullName"
                    placeholder="Enter Name Here (required)"
                    required
                />
                <input
                type="text"
                id="mailId"
                class="form-control"
                [(ngModel)]="contactus.mailId"
                name="mailId"
                placeholder="Enter mail address (required)"
                required
            />
            <input
            type="text"
            id="phoneNo"
            class="form-control"
            [(ngModel)]="contactus.phoneNo"
            name="phoneNo"
            placeholder="Enter Phone No (required)"
            required
        />
        <textarea  id="message" [(ngModel)]="contactus.message" name="message" placeholder="Enter message" required></textarea>
        <button type="submit" class="btn btn-primary" (click)="submit1()">Submit</button>
      </form>
      <div class="contact-info">
        <address>
          <p><strong>Address:</strong> Ahila Complex, Shop No:B11 & B12, Odathurai Street Thiruvaiyaru, Thanjavur - Dt,Pin - 613204, Tamil Nadu.</p>
          <p><strong>Phone:</strong>+91 98943 55288</p>
          <p><strong>Email:</strong>sbconsultancytnj&#64;gmail.com</p>
        </address>
      </div>
    </div>
  </section>
</body>
</html>

    <div class="container-fluid animatable bounceIn">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15672.40647347826!2d79.09574122823587!3d10.879875200556151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baac3fc29abc855%3A0xe3d0f49ba4f489ea!2sThiruvaiyaru%2C%20Tamil%20Nadu%20613204!5e0!3m2!1sen!2sin!4v1733306724119!5m2!1sen!2sin" width="100%" height="300" frameborder="0"  allowfullscreen="" >
        </iframe>
    </div>

 