export class contactuspojo{
    id: number | any;
    name: string | any;
    date:string |any;
    mobileNumber: number | any;
    mailId:string | any;
    enquiryType: string | any;
    message:string | any;
    firstName: string;
    middleName: string;
    mobileNo: number;
    lastName: string;
    gender: string;
    qualification: string;
    experience: string;
    dob: Date;
    address: string;
    designation: string;
    passPortNo: string;
    cSalary: string;
    city: string;
    postalCode: string;
    country: string;
    
    
}