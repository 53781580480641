<div class="title-box uk-border-rounded p-4" style="background-color: #ffffff; border: 1px solid #e1e1e1; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
  <div class="header-section bg-dark text-white p-3 rounded-top text-center">
    <h1 class="title-text font-weight-bold">Overseas Recruitment</h1>
  </div>
  <div class="breadcrumb-section mt-3 text-right">
    <h5 class="text-muted">
      <span class="sub-menu" routerLink="/home-page">Home</span>&nbsp;/&nbsp;
      <span class="sub-menu" routerLink="/web-development">Domestic Recruitment</span>
    </h5>
  </div>
</div>

<!-- Image Section -->
<div class="text-center my-4">
  <img src="../../../assets/img/home/young-group-business-office-busines.jpg" alt="Company Image" class="responsive-img">
</div>

<!-- Description Section -->
<div class="description-text text-justify mx-3">
  <p  class="animatable animate-from-right">
    We, as SB Consultants, are into Recruitment and manpower supply. We have been in this business for the last 16 years. Initially, we started with the Domestic market and now doing International manpower sourcing. We have a valid 1000+ Emigration license from the Government of India.
  </p>

  <p class="animatable animate-from-left"  style="color: #007bff; font-weight: bold;">
    Our team consists of highly skilled professionals with experience in staffing across industries such as:
  </p>

  <!-- Responsive Two-column List -->
  <div class="bullet-container">
    <div class="bullet-column">
      <ul class="animatable animate-from-right">
        <li>Oil and Gas</li>
        <li>IT</li>
        <li>Media & Advertising</li>
        <li>Manufacturing</li>
        <li>Pharmaceutical</li>
        <li>Chemical</li>
        <li>FMCG</li>
        <li>Real Estate</li>
      </ul>
    </div>
    <div class="bullet-column">
      <ul class="animatable animate-from-left">
        <li>Irrigation and Landscaping</li>
        <li>Telecom</li>
        <li>Cement</li>
        <li>Engineering</li>
        <li>Steel</li>
        <li>Hospitality</li>
        <li>Housekeeping</li>
        <li>Other niche areas of Businesses</li>
      </ul>
    </div>
  </div>
  <hr style="border: 1px solid #fb0c0c; margin: 30px 0; width: 100%;">
  <p class="animatable animate-from-right"  style="color: #007bff; font-weight: bold;">
    We are keen on providing staffing solutions across sectors and levels through a proper understanding of actual and strategic requirements:
  </p>
  <div class="bullet-container">
    <div class="bullet-column">
      <ul class="animatable animate-from-right">
        <li>Top Levels</li>
        <li>Executive</li>
        <li>Middle</li>
        <li>Labour</li>
      </ul>
    </div>
  </div> 
</div>

<style>
  /* Animation Styles */
.animatable {
  opacity: 0;
  transform: translateX(100px); /* Starts off to the right */
  transition: opacity 1s ease-out, transform 4s ease-out;
}

/* For elements that animate from the right */
.animate-from-right {
  animation: slideInFromRight 4s forwards;
}

/* For elements that animate from the left */
.animate-from-left {
  animation: slideInFromLeft 4s forwards;
}

/* Keyframes for sliding in from the right */
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keyframes for sliding in from the left */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Optional: Add a delay for the first element */
.animatable.animate-from-right {
  animation-delay: 0.2s;
}

.animatable.animate-from-left {
  animation-delay: 0.4s;
}

/* Other styling for text */
.description-text p {
  font-size: 18px; /* Adjust as per preference */
  color: #333;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .description-text p {
    font-size: 16px; /* Adjust for smaller screens */
  }
}
  /* Global Styles */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers horizontally */
    justify-content: center; /* Centers vertically */
    min-height: 100vh; /* Ensures full-page height */
    background-color: #f9f9f9; /* Optional background color */
  }

  p {
    text-align: center;
    font-size: 18px; /* Adjust as per preference */
    color: #333; /* Optional text color */
    margin: 10px 0;
  }

  .highlight-text {
    font-weight: bold;
    color: #007bff; 
  }

  /* Default Image Styling for all screen sizes */
.responsive-img {
  width: 100%;              
  height: auto;               
  object-fit: cover;        
  max-height: 500px;          
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .responsive-img {
    width: 100%;              
    height: auto;             
    object-fit: cover;        
    max-height: 300px;       
  }
}

/* For larger screen sizes like tablets and desktops */
@media (min-width: 769px) and (max-width: 1024px) {
  .responsive-img {
    width: 80%;              
    height: auto;             
    object-fit: cover;       
    max-height: 800px;       
  }
}

/* For desktop view */
@media (min-width: 1025px) {
  .responsive-img {
    width: 80%;              
    height: auto;            
    object-fit: cover;       
    max-height: 800px;        
  }
}


  /* Bullet Container */
  .bullet-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; 
    max-width: 900px;
    margin: 20px auto; 
  }

  /* Bullet Column Styling */
  .bullet-column {
    flex: 1;
    list-style: none; 
    padding: 0;
  }

  .bullet-column ul {
    padding-left: 50px; 
    margin: 0;
  }

  .bullet-column li {
    position: relative;
    margin-bottom: 10px; 
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #000;
    line-height: 1.6;
  }

  /* Custom Bullet Design */
  .bullet-column li::before {
    content: '●'; 
    position: absolute;
    left: -20px;
    /* color: #007bff; */
    font-size: 20px;
  }

  /* Media Queries for Mobile */
  @media (max-width: 768px) {
    .bullet-container {
      flex-direction: column; 
      gap: 10px; 
      margin: 10px auto; 
    }

    .bullet-column {
      padding-left: 10px;
    }

    .bullet-column ul {
      padding-left: 30px; 
    }

    .bullet-column li {
      font-size: 14px; 
      margin-bottom: 8px; 
    }

    .bullet-column li::before {
      left: -15px; 
      font-size: 18px; 
    }
  }

</style>

