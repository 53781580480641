import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.scss']
})
export class OurProductsComponent implements OnInit {

  constructor(private router: Router) { }
  ngOnInit(): void {
    this.doAnimations(); // Trigger animations on load
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.doAnimations(); // Trigger animations on scroll
  }

  doAnimations(): void {
    const offset = window.scrollY + window.innerHeight;
    const animatables = document.querySelectorAll('.animatable');

    animatables.forEach((element: HTMLElement) => {
      if (element.offsetTop < offset - 50) {
        element.classList.add('animated');
      }
    });
  }

  addAnimation(event: MouseEvent): void {
    const element = event.currentTarget as HTMLElement;
    element.style.transition = 'transform 0.3s ease, box-shadow 0.3s ease';
    element.style.transform = 'translateY(-10px)';
    element.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)';
  }

  removeAnimation(event: MouseEvent): void {
    const element = event.currentTarget as HTMLElement;
    element.style.transform = 'translateY(0)';
    element.style.boxShadow = 'none';
  }

}
